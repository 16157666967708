export default {
  games: [
    {
      id: 118,
      name: "Cooking Party",
      img: "games/118_cooking_party.jpg",
      type: 3,
      query: "?game_id=118",
      online_level: 2,
    },
    {
      id: 119,
      name: "Fruit Mary",
      img: "games/119_fruit_slots.jpg",
      type: 3,
      query: "?game_id=119",
      online_level: 2,
    },
    {
      id: 201,
      name: "Fortune Spin",
      img: "games/201_fortune_spin.jpg",
      type: 3,
      query: "?game_id=201",
      isHot: true,
      online_level: 1,
    },
    {
      id: 202,
      name: "Super Wheel",
      img: "games/202_super_wheel.jpg",
      type: 3,
      query: "?game_id=202",
      isHot: true,
      online_level: 1,
    },
    {
      id: 124,
      name: "Prairie Lion",
      img: "games/124_lion.jpg",
      type: 3,
      query: "?game_id=124",
      online_level: 3,
    },
    {
      id: 203,
      name: "Crazy Roulette",
      img: "games/203_crazy_roulette.jpg",
      type: 3,
      query: "?game_id=203",
      hide_demo: true,
      online_level: 2,
    },
    {
      id: 204,
      name: "Western Cash",
      jackpot_from_to: [100000, 1000000],
      img: "games/204_hot_777_prairie.jpg",
      type: 3,
      query: "?game_id=204",
      online_level: 3,
    },
    {
      id: 205,
      name: "Big Wheel",
      jackpot_from_to: [100000, 1000000],
      img: "games/205_spin_big.jpg",
      type: 3,
      query: "?game_id=205",
      online_level: 1,
    },
    {
      id: 206,
      name: "Fortune Dice",
      img: "games/206_dice.jpg",
      type: 5,
      query: "/dice",
      hide_demo: true,
      online_level: 2,
    },
    {
      id: 207,
      name: "Football 777",
      jackpot_from_to: [100000, 1000000],
      img: "games/209_football777.jpg",
      type: 3,
      query: "?game_id=207",
      online_level: 2,
    },
    {
      id: 208,
      name: "Football Honor",
      jackpot_from_to: [100000, 1000000],
      img: "games/208_football_honor.jpg",
      type: 3,
      query: "?game_id=208",
      online_level: 3,
    },
    {
      id: 209,
      name: "Champion Team",
      img: "games/210_championteam.jpg",
      type: 3,
      query: "?game_id=209",
      online_level: 3,
    },
    {
      id: 210,
      name: "Rich Or Crash",
      img: "games/211_rich_or_crash.jpg",
      type: 5,
      query: "/crash",
      hide_demo: true,
      online_level: 2,
    },
    {
      id: 211,
      name: "Football Trip 10000X",
      img: "games/207_10000x.jpg",
      type: 5,
      query: "/crash_football",
      hide_demo: true,
      online_level: 3,
    },
    {
      id: 212,
      name: "Penalty Taker",
      img: "games/212_penalty_taker.jpg",
      type: 3,
      query: "?game_id=212",
      online_level: 3,
    },
    {
      id: 213,
      name: "SPIN UP",
      img: "games/213_spin_up.jpg",
      type: 3,
      query: "?game_id=213",
      isHot: true,
      online_level: 1,
    },
    {
      id: 214,
      name: "One card fight",
      img: "games/214_one_card_fight.jpg",
      type: 3,
      query: "?game_id=214",
      online_level: 3,
    },
    {
      id: 215,
      name: "Destiny Wheel",
      img: "games/215_destiny_wheel.jpg",
      type: 3,
      query: "?game_id=215",
      online_level: 2,
    },
    {
      id: 216,
      name: "Dragon's Treasure",
      img: "games/216_crazy_disco.jpg",
      type: 3,
      query: "?game_id=216",
      online_level: 3,
    },
    {
      id: 217,
      name: "Slots Crazy",
      jackpot_from_to: [100000, 1000000],
      img: "games/217_slots_crazy.jpg",
      type: 3,
      query: "?game_id=217",
      online_level: 1,
    },
    {
      id: 218,
      name: "Mermaid Clots",
      img: "games/218_fishing_clash.jpg",
      type: 3,
      query: "?game_id=218",
      online_level: 3,
    },
    {
      id: 219,
      name: "Gorilla King",
      img: "games/219_secret_garden.jpg",
      type: 3,
      query: "?game_id=219",
      online_level: 3,
    },
    {
      id: 220,
      name: "X10000 Super Poker",
      img: "games/220_jackpot_x10000.jpg",
      type: 3,
      query: "?game_id=220",
      online_level: 3,
    },
    {
      id: 221,
      name: "Ninja Monogatari",
      img: "games/221_volcanic_wealth.jpg",
      type: 3,
      query: "?game_id=221",
      online_level: 3,
    },
    {
      id: 222,
      name: "Lucky Mines",
      img: "games/222_lucky_mines.jpg",
      type: 3,
      query: "?game_id=222",
      online_level: 2,
    },
    {
      id: 223,
      name: "Free Spin",
      img: "games/223_freespin.jpg",
      type: 3,
      query: "?game_id=223",
      isHot: true,
      online_level: 1,
    },
    {
      id: 224,
      name: "Lucky Dice",
      img: "games/224_lucky-dice.jpg",
      type: 3,
      query: "?game_id=224",
      online_level: 3,
    },
    {
      id: 225,
      name: "Scratch Cards",
      img: "games/225_scratch-cards.jpg",
      type: 3,
      query: "?game_id=225",
      online_level: 2,
    },
    {
      id: 227,
      name: "Red-Black Clash",
      img: "games/227_red-black-clash.jpg",
      type: 3,
      query: "?game_id=227",
      online_level: 2,
    },
    {
      id: 228,
      name: "Dragon VS Tiger",
      img: "games/228_dragon_vs_tiger.jpg",
      type: 3,
      query: "?game_id=228",
      isHot: true,
      online_level: 0,
    },
    {
      id: 229,
      name: "Best Crash",
      img: "games/229_best_crash.jpg",
      type: 3,
      query: "?game_id=229",
      hide_demo: true,
      online_level: 3,
    },
    {
      id: 230,
      name: "Zombie Town",
      img: "games/230_Speed-​​Showdown.jpg",
      type: 3,
      query: "?game_id=230",
      online_level: 1,
    },
    {
      id: 232,
      name: "Zeus",
      img: "games/232_zeus.jpg",
      type: 3,
      query: "?game_id=232",
      hide_demo: true,
      isHot: true,
      online_level: 1,
    },
    {
      id: 234,
      name: "Sex Crash",
      img: "games/234_sex-crash.jpg",
      type: 3,
      query: "?game_id=234",
      hide_demo: true,
      isHot: true,
      online_level: 1,
    },
    {
      id: 235,
      name: "Gates Of Gatotkaca",
      img: "games/235_gates_of_gatotkaca.jpg",
      type: 3,
      query: "?game_id=235",
      online_level: 2,
    },
  ],
  // games_originals: [235, 223, 201, 208, 228, 217, 229, 234, 119],
  // games_originals_b: [235, 223, 201, 208, 228, 217, 229, 234, 119],
  games_popular: [223, 201, 213, 217, 232, 234, 228, 202, 205],
  games_popular_b: [223, 201, 213, 217, 232, 234, 228, 202, 205],
  games_spin: [223, 201, 202, 205, 215, 213, 221, 203],
  games_football: [211, 208, 209, 207, 212],
  games_table_games: [212, 203, 214, 229, 234],
  games_instant_games: [206, 222, 224, 225, 227, 228],
  games_slots: [232, 204, 207, 124, 208, 209, 216, 217, 218, 118, 119, 219, 220, 230, 235],
  games_no_invite: [234, 229],
};
